@-webkit-keyframes fadeOutBackground {
  0%   {background-color: rgba(255, 236, 158, 1);}
  100% {background-color: rgba(255, 236, 158, 0);}
}
@-moz-keyframes fadeOutBackground {
  0%   {background-color: rgba(255, 236, 158, 1);}
  100% {background-color: rgba(255, 236, 158, 0);}
}
@-o-keyframes fadeOutBackground {
  0%   {background-color: rgba(255, 236, 158, 1);}
  100% {background-color: rgba(255, 236, 158, 0);}
}
@keyframes fadeOutBackground {
  0%   {background-color: rgba(255, 236, 158, 1);}
  100% {background-color: rgba(255, 236, 158, 0);}
}
