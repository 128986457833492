// Helper classes for backward-compatibility.
// Try to not have lots of these.
.text-center {
  text-align: center !important;
}
.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.clearfix {
  @include clearfix;
}
