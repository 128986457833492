@import "alembic";
// @import "../assets/vendor_src/ProgressButtonStyles/css/progressButton";

.h2-sm {
  // Reduce the size of commas in "Clients, Awards, and Press" so the commas
  // don't stand out so much
  font-size: 70%;
}
// Globally disable padding, otherwise there is too much whitespace on mobile
.typeset ul,
.typeset ol {
  padding-left: 0;
  li {
    margin-left: 1em;
    margin-bottom: 0.5em;
  }
}
ul.checklist {
  list-style-type: none;
  li {
    position: relative;
  }
  li::before {
    content: "✓";
    position: absolute;
    left: -1em;
    top: 1px;
    font-size: 120%;
  }
}
ul.dense-list {
  li {
    margin-bottom: 0;
  }
}
#principles {
  li {
    list-style-type: none;
    position: relative;
    @extend p;
  }
  .principles-icon {
    position: absolute;
    left: -1.2em;
  }
}

// Accordion
.accordion-toggle,
.accordion-toggle-all {
  text-decoration: none;
  border-bottom: 1px dotted $captionColor;
  display: inline;
  cursor: pointer;
  &::before {
    content: "Show ";
  }
  &.expanded::before {
    content: "Hide ";
  }
}
.accordion-content {
  display: none;
}
dd p {
  // Paragraphs inside a dd are used only for spacing
  margin-top: 0;
  padding-top: 0;
}

// Carousel
.carousel {
  .slick-arrow::before {
    color: $captionColor;
  }
  &-img-container {
    height: auto;
    min-height: 120px;
    @include breakpoint(break-2) {
      min-height: 240px;
    }
    @include breakpoint(break-3) {
      min-height: 320px;
    }
    @include breakpoint(break-4) {
      min-height: 440px;
    }
    position: relative;
  }
  &-img-spinner {
    position: absolute;
    top: 50px;
    @include breakpoint(break-1) {
      top: 80px;
    }
    @include breakpoint(break-2) {
      top: 100px;
    }
    @include breakpoint(break-3) {
      top: 140px;
    }
    @include breakpoint(break-4) {
      top: 200px;
    }
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
  img {
    // Hide the image alt text when the image is not loaded
    // https://stackoverflow.com/a/46109943/2142259
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    &:not([src]) {
      // Hide the border if the image is not yet loaded
      visibility: hidden;
    }
  }
}

// Portfolio
.main-img {
  img {
    display: block;
    margin: 0 auto;
    max-height: 14em;
  }
}
.press-logos {
  display: flex;
  flex-wrap: wrap;
  a,
  span {
    flex-basis: 33%;
    @include breakpoint(break-2) {
      flex-basis: 25%;
    }
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a,
  span {
    @include breakpoint(break-3) {
      flex-basis: 16%;
    }
    filter: grayscale(100%);
    transition: filter 200ms linear;
    &:hover,
    &:active {
      filter: none;
    }
    height: 75px;
  }
  img {
    max-height: 100%;
    height: auto;
    width: auto;
    &.stripe-logo {
      max-width: 90%;
    }
  }
}
.resume-section {
  border-bottom: 1px solid $captionColor;
  padding-bottom: 1em;
}
.case-category-summary {
  border-bottom: 1px solid $captionColor;
  margin-bottom: 2em;
  .case-name > a {
    // This clears the link color
    color: $headingColor;
  }
  &:last-child {
    border-bottom: none;
  }
}

// Blog list of posts
.article--post .item--post--title.item--post--title {
  color: $linkColor;
}
.item--post--title,
.item--post--description p {
  display: block;
  position: relative;
  padding-right: 4%;
  z-index: 1;
  transition: padding 0.2s ease;
}
.item--post--description p {
  padding-top: 0;
}
.article--post--bg-img {
  position: relative;
  width: 100%;
  margin-bottom: 2em;
  &:hover {
    .item--post--bg-overlay {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.99) 0%,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
    .item--post--title,
    .item--post--description p {
      padding-left: 2%;
      padding-right: 2%;
    }
  }
}
// Make the whole article clickable
.item--post--link-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  &:hover {
    text-decoration: none;
  }
}
.item--post--bg-overlay,
.item--post--bg-img {
  position: absolute;
  width: 102%;
  height: 100%;
  top: 0.1em;
  left: -1%;
  border-radius: 4px;
  margin: auto 0;
}
.item--post--bg-overlay {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.99) 50%,
    rgba(255, 255, 255, 0.75) 100%
  );
  z-index: -1;
}
// Helper class for lazy loading images
.bg-img-not-yet-loaded {
  // !important needed to override inline style in the HTML template
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.99) 50%,
    rgba(100, 100, 100, 0.75) 100%
  ) !important;
}
.item--post--bg-img {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: -2;
}

@mixin image-side-by-side-layout {
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(break-3) {
    flex: 1;
    display: flex;
    flex-direction: column;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

// Blog
.essay-content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    // Make direct links to headings copyable
    position: relative;
    &::before {
      height: 16px;
      width: 16px;
      position: absolute;
      right: 0;
      @include breakpoint(break-1) {
        left: -20px;
      }
    }
    &:hover {
      cursor: pointer;
      &::before {
        content: url(/assets/img/octicon-link.svg);
      }
    }
    @include breakpoint(break-1) {
      &.saved:hover {
        &::before {
          content: url(/assets/img/octicon-check.svg);
        }
      }
    }
  }
  .seo-img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    img {
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
    }
  }
  .img-half {
    width: 75%;
    @include breakpoint(break-1) {
      width: 50%;
    }
  }
  .img-side-by-side-container {
    @include breakpoint(break-3) {
      display: flex;
      gap: 20px;
    }
    .img-side-by-side {
      width: 75%;
      @include image-side-by-side-layout;
    }
    .img-side-by-side-trio {
      width: 90%;
      @include image-side-by-side-layout;
    }

    .img-cover.img-cover {
      img {
        object-fit: cover;
      }
    }
  }
  .img-twothirds {
    width: 90%;
    @include breakpoint(break-1) {
      width: 75%;
    }
  }
  a.fancy-link {
    text-decoration: none;
    color: inherit;

    &::after {
      position: relative;
      content: "﻿°";
      margin-left: 2px;
      margin-right: 1px;
      color: $linkColor;
    }

    &:active,
    &:hover {
      border-bottom: 1px solid $hoverColor;
      color: $hoverColor;
    }
  }
  a.footnote {
    &::before {
      content: "[";
    }
    &::after {
      content: "]";
    }
  }
  abbr {
    text-decoration: none;
    border-bottom: 1px dotted $captionColor;
  }
  blockquote {
    font-style: normal;
  }
  cite {
    display: block;
    margin-top: 1em;
    &:before {
      // https://english.stackexchange.com/questions/28601/appropriate-dash-to-use-when-attributing-a-quotation
      content: "⸺"; // U+2E3A ‭TWO-EM DASH
      padding-right: 0.4em;
    }
  }
  .video {
    a {
      // Make it so that clicking on an embedded YouTube video redirects to the video page, instead of playing
      // inside the embedded container
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      // The background image is the thumbnail video, to avoid a FOUC.
      // It uses 0.jpg instead of maxresdefault.jpg for speed
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    iframe {
      pointer-events: none;
    }
  }
  iframe {
    border: none;
    width: 100%;
  }
  .caption-img-before {
    // The image right before the caption
    margin-bottom: 0;
  }
  .caption {
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
  .call-to-action {
    font-weight: bold;
  }
  ul {
    &.apa-bibliography {
      list-style: none;
    }
  }
  .go-back {
    display: block;
    text-align: center;
    background-color: rgba(255, 236, 158, 1);
    margin: 1em;
    padding: 1em;
    cursor: pointer;
    border-radius: 0.5em;
    -webkit-transition: color 0.2s, background-color 0.2s;
    -o-transition: color 0.2s, background-color 0.2s;
    transition: color 0.2s, background-color 0.2s;
    &:hover {
      color: $white;
      background-color: #333;
    }
  }
}
.highlighted {
  border-radius: 0.5em;
  -webkit-animation: fadeOutBackground 2s 0.4s;
  -o-animation: fadeOutBackground 2s 0.4s;
  animation: fadeOutBackground 2s 0.4s;
}
.highlighted-nodelay {
  border-radius: 0.5em;
  -webkit-animation: fadeOutBackground 2s;
  -o-animation: fadeOutBackground 2s;
  animation: fadeOutBackground 2s;
}
.post-meta {
  position: relative;
  width: 100%;
  .image-credit {
    line-height: 2em;
    display: inline-block;
    @include breakpoint(break-2) {
      max-width: 55%;
    }
  }
  .time {
    line-height: 2em;
    display: block;
    @include breakpoint(break-2) {
      display: inline;
      margin-top: 0;
      position: absolute;
      right: 0;
    }
  }
}
.TwitterComments {
  .CommentComposer {
    .composer-window {
      margin-bottom: 0;
    }
    .composer-explanation {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .CommentList {
    margin-top: 1em;
  }

  .CommentBlock {
    display: flex;
    font-family: unquote(map-get($sansseriftype, font-family));
    margin-bottom: 1em;

    .author-pfp {
      flex: initial;
      margin-top: 0.5em;
      margin-right: 1em;
      border-radius: 50%;
      // Twitter's default size is 48px, but this is to ensure
      // any oversized images don't cause the layout to break
      max-height: 48px;
      max-width: 48px;
    }
    .comment-body {
      flex: auto;
      align-self: center;
    }

    .author-byline {
      font-size: 70%;
    }
    .tweet-text {
      font-size: 80%;
    }
    .author-username {
      color: $bodyColor;
      margin-right: 0.5em;
    }
    .tweet-timestamp {
      color: $captionColor;
    }

    .tweet-text {
      margin-top: -0.5em;
      padding-top: 0;
    }
    // .like-intent svg {
    //   margin-top: 0.2em;
    //   margin-right: 0.1em;
    // }
  }
}

// Bookshelf
.book-img-desktop {
  display: none;
}
@include breakpoint(break-1) {
  .book-details {
    &-container {
      display: flex;
      .book-details-no-cover {
        width: 100%;
      }
    }
    &-info {
      width: 66%;
    }
    &-image {
      // margin-top to match the margin on the h1.
      margin-top: 0.67em; // From normalize.scss
      padding-left: 1em;
      width: 33%;
    }
  }
  .book-img-mobile {
    display: none;
  }
  .book-img-desktop {
    display: block;
  }
}

// Office Hours
.book-now {
  text-align: center;
  .button,
  .caption {
    margin-bottom: 0;
  }
  .caption {
    display: block;
  }
}
.office-hours .article {
  margin-right: auto;
  @include breakpoint(break-3) {
    margin-left: 10%;
    margin-right: auto;
  }
  @include breakpoint(break-4) {
    margin-left: auto;
    margin-right: auto;
  }
}
@include breakpoint(break-1) {
  .video-call-sticky-container {
    position: relative; // Fallback for IE
    position: sticky;
    top: 1em;
  }
}
.video-call-sidebar {
  @include breakpoint(break-1) {
    position: absolute;
    width: 63%;
    left: 104%;
  }
  @include breakpoint(break-3) {
    width: 47%;
  }
  @include breakpoint(break-4) {
    width: 50%;
  }
}
blockquote.testimonial-quote {
  font-style: normal;
  border: none;
  margin-left: 0;
  margin-right: 0;
  padding: 1em;
  background-color: $lightAccentColor;
  border-radius: 0.5em;

  p:first-child {
    margin-top: 0;
  }
}
.testimonial-author {
  display: flex;
  img {
    flex: initial;
    margin-right: 1em;
  }
  cite {
    flex: auto;
    align-self: center;
    margin: 0; // override .essay-content
    &::before {
      content: none; // override .essay-content
    }
  }
  .testimonial-name,
  .testimonial-company {
    margin: 0;
    padding: 0;
  }
  .testimonial-name {
    font-weight: bold;
  }
}
.testimonial-photo {
  border-radius: 50%;
  width: 20%;
  height: 20%;
  max-width: 100px;
  max-height: 100px;
}

// Podcast
.pinecast-embed {
  margin-top: 2em;
}

// ConvertKit styles
.formkit-form.formkit-form.formkit-form.formkit-form {
  font-family: unquote(map-get($headingtype, font-family));
  [data-style="clean"] {
    padding-top: 0;
  }
}

// Sendgrid email list signup form
#subscribe-form {
  margin-top: 1em;
}
.subscribe-field-group {
  margin-top: 1.5em;
}
.subscribe-field-inline-error {
  @extend .caption;
}
.subscribe-field-inline-error.subscribe-field-inline-error,
#subscribe-errors {
  padding-top: 0;
  color: $magenta-print;
}
.progress-button:hover {
  box-shadow: none;
}

$expandableLineHeight: 1.5;
@mixin expandable {
  font-family: unquote(map-get($systemtype, font-family));
  line-height: $expandableLineHeight;
  font-size: 0.8em;
  margin: 0 auto;
}

.expandable {
  // Fade effect
  overflow: hidden;
  transition: max-height 0.3s ease;
  position: relative;

  p {
    margin: 0 0 1em 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    line-height: $expandableLineHeight;
    p {
      margin-bottom: 0.2em;
    }
  }

  &.expanded {
    max-height: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.expanded)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(transparent, $backgroundColor);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.1s ease;
  }

  &:not(.expanded):hover::after {
    opacity: 0.8; // Subtle opacity change on hover
  }
}

// AI Chatbox
.chat-container {
  @include expandable;

  .message {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    $avatarWidth: 2em;
    .avatar {
      width: $avatarWidth;
      height: $avatarWidth;
      border-radius: 50%;
      margin-left: 1px;
      margin-right: 10px;
      padding: 8px;
      background-color: white;
      border: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .message-content {
      max-width: 80%;
    }

    &.user-message {
      justify-content: flex-end;

      .message-content {
        padding: 0.5em 1em;
        border-radius: 1em;
        background-color: #f4f4f4;
        border-bottom-right-radius: 5px;
      }
    }

    &.ai-message {
      position: relative;

      code {
        background-color: $codeBackgroundColor;
        padding: 2px 4px;
        border-radius: 3px;
        font-family: unquote(map-get($monospacetype, font-family));
      }

      pre {
        background-color: $codeBackgroundColor;
        padding: 10px;
        border-radius: 5px;
        overflow-x: auto;
        margin: 1em 0;

        code {
          background-color: transparent;
          padding: 0;
        }
      }

      ul,
      ol {
        margin-top: 0;
        margin-bottom: 0;
        line-height: $expandableLineHeight;
        padding-left: $avatarWidth / 2;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: unquote(map-get($systemtype, font-family));
        font-size: unset;
        margin: 0 0 0.5em 0;
      }
    }
  }
}

// Dynamic paragraph
.dropdown-wrapper {
  display: inline-block;
  vertical-align: baseline;
  position: relative;
}

.keyword-dropdown {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-bottom: 1px dotted $captionColor;
  background: transparent;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: auto;
  min-width: 0;
  padding: 0 1em 0 0.2em;
  margin: 0;

  &:focus {
    outline: none;
    border-bottom-style: solid;
  }

  // Dropdown arrow
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 292.4 292.4'%3E%3Cpath fill='%23666' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.4-12.8z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.2em center;
  background-size: 0.5em auto;
}

// Mailchimp style overrides
#mc-embedded-subscribe-form {
  margin-top: 1em;
}
.mc-field-group {
  margin-top: 1.5em;
}
div.mce_inline_error {
  @extend .caption;
}
div.mce_inline_error.mce_inline_error {
  padding-top: 0;
  color: $magenta-print;
}
#mce-responses {
  font-weight: bold;
}

// Contact
#emailLink {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: rtl;
  unicode-bidi: bidi-override;
}

// Footer
.copyright {
  margin: auto 0;
}
