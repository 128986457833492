// Logo colors
$cyan: #00b7eb;
$cyan-print: #1dafed;
$yellow: #ffff00;
$yellow-print: #fff200;
$magenta: #ff0090;
$magenta-print: #ec008c;
$cyan-magenta: #2f3590;

// Background colors
$accentColor: #05bf85; // theme
$darkAccentColor: #0e7138;
$lightAccentColor: #f2fff4;
$backgroundColor: #ffffff; // sassline
$codeBackgroundColor: #fafafa; // sassline

// Text colors
$headingColor: #242e2b; // sassline
$bodyColor: #222222; // sassline

$linkColor: #1976d2; //#175999; // sassline
$hoverColor: $cyan-print; // sassline
$visitedColor: $linkColor; // sassline

$focusColor: lighten($hoverColor, 1%);
$captionColor: #888;
// Inverse to be used on dark backgrounds
$captionColorInverse: #a8adac; // sassline
$white: #ffffff; // sassline

// Typography
$bodytype: (
  font-family: 'Georgia, serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: '"Merriweather", serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

$monospacetype: (
  font-family: 'Monaco, monospace',
  regular: 400,
  cap-height: 0.68
);

$sansseriftype: (
  font-family: "Nunito, sans-serif",
  regular: 400,
);

$systemtype: (
  font-family: 'ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol',
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

// Font import, if you're using a non-standard web font
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');