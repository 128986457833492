body {
  background: $backgroundColor;
  color: $bodyColor;
  display: flex;
  @include flex-direction(column);
  overflow-x: hidden;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}


// Header, feature and footer
.header,
.footer {
  .nav {
    text-align: right;
  }
  .logo {
    @include flex(0 0 auto);
  }
  .container {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(space-between);
    padding: 1rem 0;
    @include breakpoint(break-1) {
      @include align-items(center);
    }
  }
}
.footer {
  font-size: 0.8em;
  .container {
    padding: 0.8em 0;
  }
}

.header .nav {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.logo {
  display: inline-block;
  line-height: 0;
  max-height: 4rem;
  @include flexbox;
  @include align-items(center);
  img {
    max-height: 5rem;
  }
}

.nav {
  a {
    padding: .2rem;
  }
  &--social a {
    display: inline-block;
    line-height: 1;
  }
}

.small {
  padding-top: .8rem;
  color: $captionColor;
  display: inline-block;
}

.footer {
  background: $headingColor;
  color: $captionColorInverse;
  a {
    color: $captionColorInverse;
    &:hover {
      color: $backgroundColor;
    }
  }
  .container {
    @include flex-wrap(wrap);
  }
  .small {
    color: $captionColorInverse;
    padding-top: 0;
  }
}

// Main content
.main {
  @include flexbox;
  @include flex-direction(column);
  @include flex(1, 0, auto);
  @include justify-content(flex-start);
  margin-bottom: 1.6rem;
  @include breakpoint(break-1) {
    @include justify-content(center);
    @include flex-direction(row);
  }
}

.header,
.feature,
.footer {
  @include flex(0, 0, auto);
}

.content {
  @include breakpoint(break-1) {
    width: 62%;
  }
}

.button {
  margin-right: .3rem;
}

.section {
  margin: 0;
}

// Lists
.list {
  list-style: none;
  &--nav {
    overflow: hidden;
    transition: .2s;
  }
  .item--post,
  .item--result,
  .item--category {
    margin-left: 0;
  }
}
.category-name {
  min-width: 4em;
  display: inline-block;
}

.item {
  &--nav {
    @include breakpoint(break-1) {
      margin-left: .4rem;
      display: inline-block;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  &--current {
    a {
      color: $captionColor;
    }
  }
}

// Media content
img {
  max-width: 100%;
  height: auto;
}

.figure {
  line-height: 0;
  &--left {
    float: left;
    padding-right: .8rem;
  }
  &--right {
    float: right;
    text-align: right;
    padding-left: .8rem;
  }
  &--center {
    text-align: center;
    clear: both;
  }
}

.video,
.map {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.map {
  padding-bottom: 70%;
}

.figure,
.video,
.map {
  margin-bottom: .8rem;
}

audio,
video {
  width: 100%;
}


// Form elements and buttons
button,
.button,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="submit"],
input[type="color"],
textarea,
select {
  padding: .6rem 1.2rem;
  margin-bottom: .6rem;
  transition: color .1s, background-color .1s, border .1s;
  line-height: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
}

input[type="submit"],
button,
.button,
a.button {
  cursor: pointer;
  display: inline-block;
  color: $backgroundColor;
  background: $accentColor;
  transition: box-shadow .1s;
  will-change: box-shadow;
  box-shadow: inset 0 0 0 2rem transparent;
  &:hover {
    box-shadow: inset 0 0 0 2rem rgba(0,0,0,0.25);
  }
  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2rem rgba(0,0,0,0.25);
  }
}

// Override a.button
.button.button--nav {
  @include breakpoint(break-1) {
    display: none;
  }
  background: none;
  color: $linkColor;
  margin-bottom: 0;
  min-height: 4rem;
  min-width: 4rem;
  &:hover,
  &:focus {
    box-shadow: none;
    color: $hoverColor;
  }
}

a,
button,
.button,
input,
textarea,
select {
  &:focus,
  &:hover:focus {
    outline: solid .12rem $focusColor;
    outline-offset: -.12rem;
  }
}

a:hover:focus {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="color"],
textarea,
select {
  width: 100%;
  border: 1px solid $captionColor;
  &:hover {
    border-color: $bodyColor;
  }
}

select {
  background: $codeBackgroundColor;
}

textarea {
    resize: vertical;
}

label {
  @include baseline($fontsize: zeta, $font: $bodytype, $lineheight: 2, $below: 2, $breakpoint: all);
}

code {
  padding: .12rem .2rem;
  color: $headingColor;
}

pre code {
  padding: 0;
}

::-webkit-input-placeholder {
  opacity: 0.5;
}
::-moz-placeholder {
  opacity: 0.5;
}
:-ms-input-placeholder {
  opacity: 0.5;
}
:-moz-placeholder {
  opacity: 0.5;
}

::selection {
  background: $magenta;
  color: $backgroundColor;
  text-shadow: none;
}


// Sassline overrides
.typeset.typeset {
  h1, .alpha {
    line-height: 1.5em;
  }

  a > code {
    text-shadow: none;
  }
  .button,
  button {
    background-image: none;
    text-shadow: none;
    color: $backgroundColor;
    &:hover,
    &:active,
    &:focus {
      background-image: none;
      color: $backgroundColor;
    }
  }
  hr {
    width: 100%;
  }
  ul, ol {
    padding-top: 0;
  }
  li {
    > p {
      padding-top: 0;
    }
  }
  .nav a {
    padding-left: 0;
    padding-right: 0;
    margin-left: .2rem;
    margin-right: .2rem;
  }
  pre {
    white-space: pre;
    overflow-x: scroll;
  }
}
