// Typography
// ---------------------------------------

// Setting root sizes and base styles.
html {
  @include rootsize;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Site-wide base styles.
body {
  @include fontsize(zeta, all);

  font-family: unquote(map-get($bodytype, font-family));
  font-style: normal;
  font-weight: map-get($bodytype, regular);
  line-height: 2rem;
}

// Links.
a {
  color: $linkColor;
  text-decoration: none;

  &:visited {
    color: $visitedColor;
  }

  &:hover, &:active, &:focus {
    color: $hoverColor;
    text-decoration: underline;
  }
}

// Styles for typeset text.
.typeset {
  // Paragraphs. OpenType ligatures and oldstyle figures enabled if available.
  p {
    @include baseline($fontsize: zeta, $font: $bodytype, $lineheight: 2, $below: 2, $breakpoint: all);

    font-feature-settings: 'kern', 'onum', 'liga';
  }

  // Headings. OpenType ligatures, discretionary ligatures and lining figures enabled if available.
  h1, h2, h3, h4, h5, h6 {
    color: $headingColor;
    font-family: unquote(map-get($headingtype, font-family));
    font-feature-settings: 'dlig', 'liga', 'lnum', 'kern';
    font-style: normal;
    font-weight: map-get($headingtype, bold);
  }

  // Heading level 1.
  h1, .alpha {
    // Syntax: sassline($fontsize, $font, $lineheight, $below, $breakpoint)
    @include sassline(alpha, $headingtype, 3, 1, all);
  }

  // Heading level 2.
  h2, .beta {
    // Override: h2s use h3 style, h3s use h4 style, etc.
    // @include sassline(beta, $headingtype, 3, 1, all);
    @include sassline(gamma, $headingtype, 3, 1, all);
  }

  // Heading level 3.
  h3, .gamma {
    @include sassline(delta, $headingtype, 3, 0, all);
  }
  .gamma-below {
    @include sassline(gamma, $headingtype, 3, 1, all);
  }

  // Heading level 4.
  h4, .delta {
    // @include sassline(delta, $headingtype, 2, 0, all);
    @include sassline(epsilon, $headingtype, 2, 0, all);
  }

  // Heading level 5.
  h5, .epsilon {
    // @include sassline(epsilon, $headingtype, 2, 0, all);
    @include sassline(zeta, $headingtype, 2, 0, all);
  }

  // Heading level 6.
  h6, .zeta {
    @include sassline(zeta, $headingtype, 2, 0, all);
    font-weight: normal;
  }

  // Lists.
  ul, ol {
    @include baseline(zeta, $bodytype, 2, 2, all);

    li {
      font-feature-settings: 'kern', 'onum', 'liga';
      margin-left: 2rem;

      @include breakpoint(break-1) {
        margin-left: 0;
      }

      ol, ul {
        padding-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 2rem;
      }
    }
  }

  // Ordered lists.
  ol {
    list-style-type: none;

    >li {
        counter-increment: top-level;
    }
    li {

      &:before {
        content: counter(top-level) '.';
        font-feature-settings: 'lnum', 'tnum';
        margin-left: -3rem;
        position: absolute;
        text-align: right;
        width: 2em;
      }

      ul {

        li {

          &:before {
            content: '';
          }

          ol {

            li {
              counter-increment: alt-level;

              &:before {
                content: counter(alt-level) '.';
              }
            }
          }
        }
      }

      ol {

        li {
          counter-increment: sub-level;

          &:before {
            content: counter(top-level) '.' counter(sub-level);
          }

          ul {

            li {

              &:before {
                content: '';
              }
            }
          }

          ol {

            li {
              counter-increment: sub-sub-level;

              &:before {
                content: counter(top-level) '.' counter(sub-level) '.' counter(sub-sub-level);
              }
            }
          }
        }
      }
    }
  }

  // Definition lists.
  dl {
    @include baseline(zeta, $bodytype, 2, 2, all);

    dt, dd {
      font-feature-settings: 'kern', 'onum', 'liga';
      margin-left: 2rem;

      @include breakpoint(break-1) {
        margin-left: 0;
      }
    }

    dt {
      font-weight: map-get($bodytype, bold);
    }

    dd + dt {
      padding-top: 1rem;
    }
  }

  // Tables.
  table {
    @include sassline(eta, $headingtype, 2, 0, all);

    font-family: unquote(map-get($headingtype, font-family));
    font-feature-settings: 'liga', 'lnum', 'tnum', 'kern';
    font-style: normal;
    font-weight: map-get($headingtype, regular);
    width: 100%;

    thead {

      th {
        @include sassline(zeta, $headingtype, 2, 0, all);
        padding-bottom: 1px;
      }
    }
  }

  // Bold.
  b, strong, .bold {
    font-weight: map-get($bodytype, bold);
  }

  // Italic.
  em, i, .italic {
    font-style: map-get($bodytype, italic);
  }

  // Caption and inline small text.
  small, .caption {
    @include fontsize(theta, all);

    font-family: unquote(map-get($headingtype, font-family));
    font-style: normal;
    font-weight: map-get($headingtype, regular);
  }

  small {
    line-height: 1rem;
  }

  .caption {
    @include baseline(theta, $headingtype, 2, 2, all);

    color: $captionColor;
  }

  // Nice spacing for captions.
  h1 + .caption, .alpha + .caption, h2 + .caption, .beta + .caption, h3 + .caption, .gamma + .caption {
    margin-top: -1rem;
  }

  .delta + .caption, .epsilon + .caption, .zeta + .caption {
    margin-top: 0rem;
  }

  // Quotes.
  blockquote {
    border-left: 0.15rem solid $linkColor;
    font-style: map-get($bodytype, italic);
    padding-left: 1rem;

    // Add spacing below blockquote paragraphs to align to baseline grid.
    $get-scale: map-get($modular-scale, scale-0);
    $get-size: map-get($get-scale, zeta);
    $rootsize: nth($sizes, 1);
    $baseline-shift: #{($get-size / 2 * ((2 * $rootsize / $get-size) - map-get($bodytype, cap-height))) / $rootsize + 0.00001};
    $baseline-push: #{3 - (($get-size * ((2 * $rootsize / $get-size) - map-get($bodytype, cap-height))) / $rootsize + 0.00001)};

    margin-bottom: #{$baseline-push}rem;
    padding-bottom: #{$baseline-shift}rem;

    @for $i from 2 through $breakpoints-limit {
      $get-scale: map-get($modular-scale, scale-#{$i - 1});
      $get-size: map-get($get-scale, zeta);
      $rootsize: nth($sizes, $i);
      $baseline-shift: #{($get-size / 2 * ((2 * $rootsize / $get-size) - map-get($bodytype, cap-height))) / $rootsize + 0.00001};
      $baseline-push: #{3 - (($get-size * ((2 * $rootsize / $get-size) - map-get($bodytype, cap-height))) / $rootsize + 0.00001)};

      @media screen and (min-width: nth($points, $i) / 16 * 1em ) {
        margin-bottom: #{$baseline-push}rem;
        padding-bottom: #{$baseline-shift}rem;
      }

      > p:last-child {
        margin-bottom: 0;
      }
    }

    @include breakpoint(break-1) {
      margin-left: -1rem;
    }
  }

  // Horizontal rule.
  hr {
    background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0) 50%,$captionColor 50%);
    background-position: 0 50%;
    background-repeat: repeat-x;
    background-size: 100% 0.15rem;
    border: 0;
    margin: 0;
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  // Code block.
  code, pre {
    background-color: $codeBackgroundColor;
    font-family: unquote(map-get($monospacetype, font-family));
  }

  pre {
    display: block;
    margin-bottom: 2rem;
    padding: 1rem;
    white-space: pre;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
  }

  code {
    @include fontsize(theta, all);

    line-height: 1rem;
  }

  // Letter space those capitals people, Jan Tschichold would be proud.
  .upper {
    font-kerning: normal;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  // Real small caps.
  .small-caps {
    font-feature-settings: 'smcp', 'kern';
    font-kerning: normal;
    letter-spacing: 0.1rem;
  }

  // Consistent height numbers with OpenType.
  .lining-numerals {
    font-feature-settings: 'lnum', 'kern';
  }

  // Ascending and descending numbers with OpenType.
  .oldstyle-numerals {
    font-feature-settings: 'onum', 'kern';
  }
}
